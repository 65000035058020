var console = window.console || {
	log : function() {
	}
};
(function() {
//	$.browser.msie = false;
//	$.browser.version = 0;
//	if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
//		$.browser.msie = true;
//		$.browser.version = RegExp.$1;
//	}
//	$(document).on("contextmenu", function(event) {
//		return false;
//	}); //우클릭방지
//	$(document).on("selectstart", function(event) {
//		return false;
//	}); //더블클릭을 통한 선택방지
//	$(document).on("dragstart", function(event) {
//		return false;
//	}); //드래그
})();
if (typeof String.prototype.trim !== 'function') {
	String.prototype.trim = function() {
		return this.replace(/\s\s*$/, '');
	}
}
function addCommas(num) {
	if (num == null || num == undefined || num == '') {
		return 0;
	} else {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
}
function noCommas(num) {
	if (num == null || num == undefined || num == '') {
		return 0;
	} else {
		return num.toString().replace(/,/g, '');
	}
}
function nvl(num) {
	if (isNumeric(num)) {
		return num;
	} else {
		return 0;
	}
}
function isEmpty(obj) {
	if (obj == null || obj == undefined || obj == 'null' || obj == 'undefined' || obj == '') {
		return true;
	} else {
		return false;
	}
}
function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

// 01로 시작하는 핸드폰 및 지역번호와 050, 070 검증
// 원래 050은 0505 평생번호인가 그런데 보편적으로 050-5xxx-xxxx 로 인식함
// 0505-xxx-xxxx 라는 식으로 넣으면 통과할 수 없음. 그래서 경고창 띄울때 예시 넣는것이 좋음.
// -(하이픈)은 넣어도 되고 생략해도 되나 넣을 때에는 정확한 위치에 넣어야 함.
function isValidPhone(no) {
	if (no != '') {
		var regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
		if (!regExp.test(no)) {
			commonAlert("잘못된 전화번호 형식입니다.");
			return false
		}
	}
	return true;
}
var setArrow = function(obj) {
	$(obj).closest('.selWrap').find('span.sel_tlt').html($(obj).html() + '<span class="arrow_sel">select화살표</span>');
}
var submit = {
	ajax : function(action, param, callbackFunction) {
		$.ajax({
			type : this.type,
			url : action,
			data : param,
			async : false,
			beforeSend : function(request) {
				showLoadingLayer();
				var csrfToken = $('meta[name="_csrf"]').attr('content') || '';
				var csrfName = $('meta[name="_csrf_header"]').attr('content') || '';
				if (this.type.toLowerCase() == 'post' && csrfName != '' && csrfToken != '') {
					request.setRequestHeader(csrfName, csrfToken);
				}
			},
			success : function(data) {
				hideLoadingLayer(true);
				callbackFunction(data);
			},
			error : function(xhr) {
				hideLoadingLayer(true);
				var data = $.parseJSON(xhr.responseText)
				alert(data.message);
			},
			complete : function(data) {
				hideLoadingLayer(true);
			}
		});
	},
	form : function(frmObj, action, param) {
		$(frmObj).submit(function(e) {
			submit.type = 'post';
			submit.ajax(action, $(frmObj).serialize(), function(data) {
				// console.log("data :" + data);
			});

			e.preventDefault(); // STOP default action
			e.unbind(); // unbind. to stop multiple form submit.
		});
	},
	secureSubmit : function(frmObj) {
//		var csrfToken = $("meta[name='_csrf']").attr("content");
//		var csrfName = $("meta[name='_csrf.parameter']").attr("content");
//		$(frmObj).append('<input type="hidden" name="' + csrfName + '" value="' + csrfToken + '" />');
		$(frmObj).removeAttr('onsubmit');
		$(frmObj).submit();
	},
	type : 'post'
}
var popup = {
	layer : function(action, pid, strParams, paging) {
		submit.type = 'post';
		submit.ajax(action, strParams, function(data) {
			if (paging) {
				$('.deliverySelWrap').html($(data).find('.deliverySelWrap').html());
				$('.pagingWrap').html($(data).find('.pagingWrap').html());
			} else {
				if (pid == 'pop_delivery_multi') {
					$('[name="multiDlv"]').attr('id', pid);
					$('[name="multiDlv"]').html(data);
				} else {
					$('[name="layerPopup"]').attr('id', pid);
					$('[name="layerPopup"]').html(data);
				}
				setLayerPopDirect(pid);
				// $("#layerPopup").html(data);
				// $("#layerPopup").html(data).click(function(){
				// init();
				// });
			}
		});
	},
	close : function(obj) {
		$(obj).closest('.fullpop').hide();
		$('.allWrap').removeClass('on');
		$('body').scrollTop(beforeScr);
		return;
	}
};
function openCommonPopup(actionUrl, params, pWidth, pHeight, winNm) {
	if (actionUrl != undefined) {
		var winName = "ssfPop";
		if (winNm != undefined) {
			winName = winNm;
		}
		if (pWidth == undefined) {
			pWidth = 680;
		}
		if (pHeight == undefined) {
			pHeight = 660;
		}
		openPopup(actionUrl, params, winName, pHeight, pWidth);
	}
}

//1단위 올림
var ceil10 = function(val) {
	return Math.ceil(nvl(val) * 0.1) * 10;
};

//10단위 올림
var ceil100 = function(val) {
	return Math.ceil(nvl(val) * 0.01) * 100;
};

//1단위 반올림
var round10 = function(val) {
	return Math.round(nvl(val) * 0.1) * 10;
};

// 10단위 반올림
var round100 = function(val) {
	return Math.round(nvl(val) * 0.01) * 100;
};

//10단위 절사
var floor100 = function(val) {
	return Math.floor(nvl(val) * 0.01) * 100;
};

var orderAlert = function(str) {
	commonAlert(str);
};

